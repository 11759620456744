import React, { useState, useContext } from 'react';
import './About.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import one from './about_icons/1.png';
import two from './about_icons/2.png';
import three from './about_icons/3.png';
import four from './about_icons/4.png';
import five from './about_icons/5.png';
import six from './about_icons/6.png';
import Navbar from './Navbar';

interface AboutProps {
  loading: boolean; // Add this prop
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const About: React.FC<AboutProps> = ({ loading, setLoading }) => {
  const navigate = useNavigate();


  return (
    <div className="about-wrapper">
      <div className="navbar-admin-wrapper">
        <Navbar/>
      </div>
      <div className='horizontal-about-section'>
        <section className="about-hero">
          <div className="hero-text">
            <h2>Revolutionizing Financial Modeling</h2>
            <p>
              Monarq is a powerful platform designed to streamline the creation of financial models in Excel, enabling financial analysts to work faster and more efficiently.
            </p>
          </div>
        </section>
        <section className="about-content">
          <div className="content-block">
            <h3>Streamlined Workflows</h3>
            <p>
              By seamlessly integrating trusted third-party data from Visible Alpha, Monarq eliminates the need for manual data entry and ensures your models are always updated with the latest, most accurate information.
            </p>
          </div>
          <div className="content-block">
            <h3>Enhanced Efficiency</h3>
            <p>
              With Monarq, analysts can quickly access and incorporate relevant data into their financial models, customize key revenue parameters, and reduce the time spent on building and updating complex models.
            </p>
          </div>
          <div className="content-block">
            <h3>Drive Better Outcomes</h3>
            <p>
              Whether you’re working with simple forecasts or sophisticated multi-sheet models, Monarq makes it easier to access and manipulate the data you need to drive better business outcomes.
            </p>
          </div>
        </section>
      </div>

      <div className='horizontal-about-section-gradient'>
        <div className="about-section-container">
          <div className="about-content-wrapper">
            <div className="about-grid">
              <div className="about-feature">
                <div className="feature-icon-wrapper">
                  <div className="icon-container">
                    <img src={one} className="about-icon-img" />
                  </div>
                  <h4 className="feature-title">Efficient Model Building</h4> 
                  <h5 className="feature-description">In just a few clicks, build a fully functional, industry-standard financial model—no complex setup, no manual data entry, no overhead. Monarq streamlines the process, enabling you to create comprehensive models quickly and effortlessly.</h5>
                </div>
                <div className="feature-icon-wrapper">
                  <div className="icon-container">
                  <img src={two} className="about-icon-img" />
                  </div>
                  <h4 className="feature-title">Integration with Visible Alpha</h4> 
                  <h5 className="feature-description">Access real-time, high-quality financial data from Visible Alpha, including company-specific revenue parameters, consensus forecasts, and other key metrics—all integrated directly into your Excel models.</h5>
                </div>
                <div className="feature-icon-wrapper">
                  <div className="icon-container">
                  <img src={three} className="about-icon-img" />
                  </div>
                  <h4 className="feature-title">Customizable Revenue Parameters</h4> 
                  <h5 className="feature-description">Tailor your financial models with company-specific custom revenue parameters ensuring that forecasts reflect your unique analysis.</h5>
                </div>
                <div className="feature-icon-wrapper">
                  <div className="icon-container">
                    <img src={four} className="about-icon-img" />
                  </div>
                  <h4 className="feature-title">Faster Model Creation</h4> 
                  <h5 className="feature-description">Build and update financial models in a fraction of the time, using the latest, most accurate data. Monarq makes it easy to create fully functional models quickly, allowing analysts to focus on analysis and insights rather than manual processes.</h5>
                </div>
                <div className="feature-icon-wrapper">
                  <div className="icon-container">
                  <img src={five} className="about-icon-img" />
                  </div>
                  <h4 className="feature-title">Flexibility</h4> 
                  <h5 className="feature-description">Monarq allows analysts to quickly generate and customize financial models, making it easy to explore new business opportunities, scenarios, and "what-if" analyses with minimal setup time.</h5>
                </div>
                <div className="feature-icon-wrapper">
                  <div className="icon-container">
                  <img src={six} className="about-icon-img" />
                  </div>
                  <h4 className="feature-title">Reduced Manual Work</h4> 
                  <h5 className="feature-description">Minimize manual effort and reduce human error by automatically integrating external data and model updates, saving you valuable time.</h5>
                </div>
              </div>
              </div>
            </div>
        </div>
      </div>
      {/* FAQ Section */}
      <div className='horizontal-about-section-full'>
        <div className="col-acoordion">
          <div className="faq-container">
            <div className="faq-accordion-first">
              <p className="faq-question">What is Monarq and how does it help financial analysts?</p>
              <span className="accordion-item__icon"></span>
            </div>
            <div className="faq-panel">
              <p>Monarq is a financial modeling platform that simplifies and speeds up the creation of complex models in Excel. By integrating trusted third-party data from Visible Alpha directly into the model creation process, Monarq removes the need for manual data entry. This saves you time and reduces errors, enabling you to focus on higher-value analysis and decision-making. You can also customize revenue parameters and other assumptions to ensure your models are tailored to your business needs.
              </p>
            </div>

            <div className="faq-item">
              <div className="faq-accordion">
                <h1 className="faq-question">Can I customize revenue assumptions in my models?</h1>
              </div>
              <div className="faq-panel">
                <p>Yes! Monarq allows you to define and customize key, company-specific revenue parameters used to generate the model. This flexibility ensures your financial models reflect the unique conditions of your business, leading to more accurate and tailored forecasts.
                </p>
              </div>
            </div>

            <div className="faq-accordion">
              <p className="faq-question">How does Monarq make running financial models faster?</p>
              <span className="accordion-item__icon"></span>
            </div>
            <div className="faq-panel">
              <p>Monarq significantly accelerates model creation by reducing the time spent on manual data entry and model maintenance, allowing analysts to focus on high-value activities like strategic analysis and decision-making.
              </p>
            </div>

            <div className="faq-accordion">
              <p className="faq-question">How does Monarq keep my financial models secure?</p>
              <span className="accordion-item__icon"></span>
            </div>
            <div className="faq-panel">
              <p>Monarq takes data security seriously. We do not store any customer data on our servers. All data accessed via the platform is securely retrieved and automatically populated into your models, without any persistence of user-specific data.
              </p>
            </div>

            <div className="faq-accordion">
              <p className="faq-question">Do you store any of my data?</p>
              <span className="accordion-item__icon"></span>
            </div>
            <div className="faq-panel">
              <p>No, Monarq does not store any customer data. Our platform acts as a data transformer and conduit with no storage of user data or credentials.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
