import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { loadUserData, clearAllCachedData } from './AuthCache';
import './Navbar.css';
import logo from './monarq/logo.png';

const Navbar: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const { token, isAdmin, validCachedToken } = loadUserData();

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    clearAllCachedData();
    navigate('/login');
  };

  // Update the isMobile state based on window size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="navbar-content">
          <img src={logo} className="logo" />
          <div className="navbar-spacer"></div>
          {isMobile ? (
            <>
              <div className={`menu-button-wrapper ${isMenuOpen ? 'menu-open' : ''}`}>
                <button
                  type="button"
                  aria-label="Drawer Menu Button"
                  className="menu-button"
                  onClick={handleMenuClick}
                >
                  <svg aria-hidden="true" fill="#FFFFFF" height="30" width="30">
                    <g className={`menu-bar top-bar ${isMenuOpen ? 'rotate-bar-top' : ''}`}>
                      <rect fill="transparent" height="30" width="30" x="0" y="0"></rect>
                      <rect height="3" width="30" x="0" y="7"></rect>
                    </g>
                    <g className={`menu-bar bottom-bar ${isMenuOpen ? 'rotate-bar-bottom' : ''}`}>
                      <rect fill="transparent" height="30" width="30" x="0" y="0"></rect>
                      <rect height="3" width="30" x="0" y="20"></rect>
                    </g>
                  </svg>
                </button>
              </div>
              {isMenuOpen && (
                <div className='drawer-menu-content'>
                <ul className='drawer-menu-content-list-wrapper'>
                  {validCachedToken && isAdmin && token && (
                    <li className='drawer-menu-content-list-item'>
                      <div className='drawer-menu-content-list-item-wrapper'>
                        <a className="drawer-menu-content-list-item-link" href="/admin">
                          <span className="drawer-menu-content-list-item-link-text">Admin</span>
                        </a>
                      </div>
                    </li>
                  )}
                  <li className='drawer-menu-content-list-item'>
                    <div className='drawer-menu-content-list-item-wrapper'>
                      <a className="drawer-menu-content-list-item-link" href="/">
                        <span className="drawer-menu-content-list-item-link-text">Home</span>
                      </a>
                    </div>
                  </li>
                  <li className='drawer-menu-content-list-item'>
                    <div className='drawer-menu-content-list-item-wrapper'>
                      <a className="drawer-menu-content-list-item-link" href="/about">
                        <span className="drawer-menu-content-list-item-link-text">About</span>
                      </a>
                    </div>
                  </li>
                  {validCachedToken && token && (
                    <li className='drawer-menu-content-list-item'>
                      <div className='drawer-menu-content-list-item-wrapper'>
                        <button className="drawer-menu-content-list-item-link" onClick={handleLogout}>
                          <span className="drawer-menu-content-list-item-link-text">Logout</span>
                        </button>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
              )}
            </>
          ) : (
            <div className="navbar-links">
              {validCachedToken && isAdmin && token && (
                <Link to="/admin"><div className="navbar-link admin">Admin</div></Link>
              )}
              <div className="navbar-link-spacer"></div>
              <Link to="/"><div className="navbar-link admin">Home</div></Link>
              <div className="navbar-link-spacer"></div>
              <Link to="/about"><div className="navbar-link admin">About</div></Link>
              <div className="navbar-link-spacer"></div>
              {validCachedToken && token && (
                <div className="navbar-link">
                    <button className="navbar-logout-button" onClick={handleLogout}>
                      Logout
                    </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
